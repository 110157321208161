.custom-popover-parent{
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 27;
    /* background: white; */
    width: 100%;
    height: 100%;
}
.custom-popover-child{
    display: inline-block;
    /* width: 300px; */
    background: white;
    /* height: 353px; */
    position: absolute;
    left: 88px;
    top: 23px;
    z-index: 99999;
    box-shadow: 0px 0px 8px -1px #d6d2d2;
}