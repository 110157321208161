.css-1ks9aww-gutter {
    background: unset !important;
    border-left: 1px solid #e0e0e0;
}
.css-1ks9aww-gutter.css-cnnxkz-diff-added{
    background: #cdffd8 !important;
}
.css-1ks9aww-gutter.css-rq9a2a-diff-removed{
    background: #ffdce0 !important;
}
/* .css-10regm7-empty-line{
    background-color: unset !important;
} */
